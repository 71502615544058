.onboarding-title {
    text-align: center;
}

.onboarding-subtitle {
    text-align: center;
}

.onboarding-subtitle {
}

.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

.onboarding-big-button {
    margin-bottom: 32px;
}

.onboarding-inner-container {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
}

.onboarding-galleries-container {
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
}

.onboarding-gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;
    width: 100%;
    border-radius: 8px;
    background-color: #ff67cfBF;
    padding: 8px;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px white;
    overflow: hidden;
}

.onboarding-gallery {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    max-width: 80%;
}

.onboarding-remove-button {
    height: 16px;
    width: 16px;
    padding: 0;
}

.onboarding-gallery-add {
    color: #ff67cf;
    background-color: white;
    margin-top: 16px;
    text-decoration: none;
    box-shadow: 1px 1px 4px white;
}

.onboarding-galleries-list {
    box-sizing: border-box;
    height: 239px;
    max-height: 239px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    border-radius: 8px;
    background: rgb(255,157,223);
    background: linear-gradient(127deg, rgba(255,157,223,1) 0%, rgba(255,157,223,1) 15%, rgba(255,133,216,1) 62%, rgba(255,157,223,1) 120%);
}

.onboarding-notifications-skip {
    margin-bottom: 8px;
}