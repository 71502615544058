.inspect-button {
    background: url('./img/zoom-in.svg');
    background-size: contain;
  }

  .move-button {
    background: url('./img/arrows-move.svg');
    background-size: contain;
  }

  .swap-button {
    background: url('./img/swap-canvases.svg');
    background-size: contain;
    right: 0;
    bottom: calc(246px + env(safe-area-inset-bottom));
  }

  .swap-button-joystick {
    bottom: calc(284px + env(safe-area-inset-bottom)) !important;
  }

  .confirm-button {
    background: url('./img/check-lg.svg');
    background-size: contain;
    background-position-y: 3px;
    background-position-x: -1px;
    opacity: 1;
  }

  .cancel-button {
    background: url('./img/x-lg.svg');
    background-size: contain;
    opacity: 1;
  }

  .center-button-left {
    left: calc(50% - 58px - 8px);
    bottom: calc(82px + env(safe-area-inset-bottom));
  }

  .center-button-right {
    left: calc(50% + 8px);
    bottom: calc(82px + env(safe-area-inset-bottom));
    background-color: white;
    border-radius: 58px;
  }

  /* @media (min-width: 720px) {
    .top-button {
      bottom: 132px;
    }
  } */

  /* @media (max-width: 720px) { */
    .top-button {
      right: 0;
      bottom: calc(82px + env(safe-area-inset-bottom));
    }
  /* } */

  .top-button-joystick {
    right: 0;
    bottom: calc(120px + env(safe-area-inset-bottom));
  }

.upper-top-button {
  right: 0;
  bottom: calc(164px + env(safe-area-inset-bottom));
}

.upper-top-button-joystick {
  right: 0;
  bottom: calc(202px + env(safe-area-inset-bottom));
}

.bottom-button {
  right: 0;
  bottom: calc(0px + env(safe-area-inset-bottom));
}

.emote-button {
  right: 0;
  bottom: calc(0px + env(safe-area-inset-bottom));
  background: url('./img/suit-heart-fill.svg');
  background-size: contain;
}

.open-menu-button {
right: 0;
top: calc(0px + env(safe-area-inset-top));
background: url('./img/list.svg');
background-size: contain;
}

.current-address {
  min-width: 0;
  position: absolute;
  right: 82px;
  top: calc(0px + env(safe-area-inset-top) + 10px);
  max-width: 148px;
  margin: 16px;
}

@media (max-width: 720px) {
  .current-address {
    display: none !important;
  }
}

.current-address-show-on-mobile {
  min-width: 0;
  position: absolute;
  right: 82px;
  top: calc(0px + env(safe-area-inset-top) + 10px);
  max-width: 148px;
  margin: 16px;
}

.current-address-text {
  font-size: inherit;
  font: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 148px;
  display: block;
}

.mute-button {
  left: 0;
  top: calc(0px + env(safe-area-inset-top));
  background: url('./img/volume-up-fill.svg');
  background-size: contain;
}

/* @media (min-width: 720px) {
  .mute-button {
    left: 132px;
  }
} */

/* @media (max-width: 720px) { */
  .mute-button {
    left: 82px;
  }
/* } */

.mute-button-muted {
  background: url('./img/volume-mute-fill.svg');
  background-size: contain;
}

.notification-badge {
  position: absolute;
  height: 48px;
  width: 48px;
  top: calc(12px + env(safe-area-inset-top));
  right: 12px;
  opacity: 0.6;
  border-radius: 48px;
  background-color: white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

/* @media (max-width: 720px) { */
  .notification-badge {
    right: 12px;
    top: calc(12px + env(safe-area-inset-top));
    height: 24px;
    width: 24px;
    border-radius: 24px;
  }
/* } */

.dash-button {
right: 0;
bottom: 0;
background: url('./img/Heart.png');
background-size: contain;
}

#renderCanvas {
  height: 100%;
  width: 100%;
}

.chat-preview {
  position: absolute;
  top: calc(82px + env(safe-area-inset-top));
  right: 16px;
  height: 64px;
  width: 192px;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  color:#ff85d8;
  box-shadow: 1px 1px 4px white;
  transition: opacity 2s ease, transform 2.4s ease;
  opacity: 0;
  transform: translate(0px, -8px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat-preview-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 1;
  transform: translate(0px, 0px);
}

@media (max-width: 720px) {
  .chat-preview {
    width: 128px;
    right: 16px;
  }
}

.chat-preview-message {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.space-input {
  position: absolute;
  top: 32px;
  left: 50%;
  background-color: white;
}

.joystick {
  position: absolute;
  bottom: calc(0px + env(safe-area-inset-bottom));
  height: 120px;
  width: 120px;
  color: white;
  box-sizing: border-box;
}

.left-joystick {
  left: 0;
}

.right-joystick {
  right: 0;
}

.joystick-front {
  width: 75px !important;
  height: 75px !important;
  margin-left: -37.5px !important;
  margin-top: -37.5px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4) !important;
  opacity: 1 !important;
  box-shadow: 1px 1px 6px black;
  text-shadow: 2px 2px 2px #000000;
}

.joystick-back {
  background-color: rgba(255, 255, 255, 0.2) !important;
  opacity: 1 !important;
  box-shadow: 1px 1px 6px black;
}