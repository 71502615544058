.mobile-home-title {
    text-align: center;
}

.mobile-home-subtitle {
    text-align: center;
}

.mobile-home-subtitle {
}

.mobile-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-top: calc(32px + env(safe-area-inset-top));
    padding-bottom: calc(32px + env(safe-area-inset-bottom));
}

.mobile-home-big-button {
    margin-bottom: 32px;
}

.mobile-home-inner-container {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
}

.mobile-home-galleries-list-container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 60.6%;
    max-height: 60.6%;
}

.mobile-home-galleries-container {
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 32px;
}

.mobile-home-gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 20px;
}

.mobile-home-gallery {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    color: white;
    min-width: 0;
    text-decoration: none;
}

.mobile-home-gallery-button {
    background-size: 400% 400%;
	animation: gradient 5s ease infinite;
    box-shadow: 0.5px 1px 6px white;
    box-sizing: border-box;
    height: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 16px;
    min-width: 0;
    margin-left: 16px;
    cursor: pointer;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.mobile-home-gallery-button-row {
    display: flex;
}

.mobile-home-remove-button {
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255,157,223);
    background: linear-gradient(127deg, rgba(255,157,223,1) 0%, rgba(255,157,223,1) 15%, rgba(255,133,216,1) 62%, rgba(255,157,223,1) 120%);
    opacity: 1;
    margin-left: 12px;
}

.mobile-home-remove-button:last-of-type {
    margin-right: 16px;
}

.mobile-home-remove-button-inactive {
    opacity: 0.4;
    box-shadow: none;
}

.mobile-home-remove-image {
    height: 16px;
    width: 16px;
    padding: 0;
}

.mobile-home-gallery-add {
    color: white;
    background: linear-gradient(127deg, rgba(255,157,223,1) 0%, rgba(255,157,223,1) 15%, rgba(255,133,216,1) 62%, rgba(255,157,223,1) 120%);
    margin-top: 16px;
    text-decoration: none;
    align-self: flex-end;
    padding-left: 16px;
    padding-right: 16px;
}

.mobile-home-galleries-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    border-radius: 8px;
    height: 100%;
    max-height: 100%;
    background: rgb(255,157,223);
    background: linear-gradient(127deg, rgba(255,157,223,1) 25%, rgba(255,157,223,1) 63%, rgba(255,133,216,1) 120%);
    padding-top: 16px;
    padding-bottom: 16px;
}

.mobile-home-notifications-skip {
    margin-bottom: 8px;
}

.mobile-home-another-gallery-container {
    max-width: 100%;
    width: 100%;
}

.mobile-home-another-gallery-subtitle {
    margin-bottom: 8px;
}

.mobile-home-address-input {
    flex: 1;
    font-size: 16px;
}

.mobile-home-input-row {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}