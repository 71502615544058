.sf-finalize-canvas {
    width: 375px;
    height: 375px;
    border-radius: 32px;
    box-shadow: 1px 1px 8px white;
}

@media (max-width: 720px) {
    .sf-finalize-canvas {
        width: 300px;
        height: 300px
    }
}

.sf-finalize-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sf-finalize-header {
    height: 16%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;
}

.sf-finalize-title {
    text-align: center;
    font-size: 36px;;
}

@media (max-width: 720px) {
    .sf-finalize-title {
        font-size: 18px;
    }
}

.sf-finalize-buttons {
    margin-top: 32px;
    height: 16%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.sf-finalize-error {
    margin-bottom: 16px;
    background-color: #FE0302;
    border-radius: 4px;
    padding: 8px;
}

.sf-mint-button {
    width: 360px;
}

@media (max-width: 720px) {
    .sf-mint-button {
        width: 180px;
        font-size: 18px;
    }
}