.space-factory-customize-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

@media (max-width: 720px) {
    .space-factory-customize-container {
        flex-direction: column;
    }
}

.space-factory-customize-3d {
    flex: 2;
    position: relative;
}

@media (max-width: 720px) {
    .space-factory-customize-3d {
        width: 100%;
        height: 50vh;
    }
}

.generated-space-name {
    position: absolute;
    top: 32px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    }

.space-factory-customize-menu {
    min-width: 560px;
    flex: 1;
    max-height: 100%;
}

@media (max-width: 720px) {
    .space-factory-customize-menu {
        min-width: unset;
    }
}

.space-factory-customize-canvas {
    width: 100%;
    height: 100%;
}

.sf-tab-inner-body {
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    height: calc(100vh - 64px);
    padding: 32px;
    box-sizing: border-box;
}

@media (max-width: 720px) {
    .sf-tab-inner-body {
        max-height: calc(100vh - 50vh - 64px);
        height: calc(100vh - 50vh - 64px);
        padding: 16px;
    }
}

.sf-tab-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100vh - 64px);
    box-sizing: border-box;
}

@media (max-width: 720px) {
    .sf-tab-body {
        max-height: calc(100vh - 50vh - 64px);
        height: calc(100vh - 50vh - 64px);
    }
}

.sf-finish-body {
    width: 100%;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 720px) {
    .sf-finish-body {
        height: calc(100vh - 50vh - 64px);
    }
}

.sf-customize-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

@media (max-width: 720px) {
    .sf-customize-section {
        gap: 12px;
    }
}

.sf-customize-section-label:not(:first-of-type) {
}

.sf-customize-section-label {
}

.sf-customize-space {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 1px 1px 4px white;
    overflow: hidden;
    box-sizing: border-box;
    opacity: 0.2;
}

@media (min-width: 720px) {
    .sf-customize-space {
        height: 144px;
        width: 144px;
        min-height: 144px;
        min-width: 144px;
    }
}

.sf-customize-space-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
    opacity: 0.2;
}

.sf-customize-space-label-first {
    margin-top: 8px;
}

.sf-customize-space-label-truncate {
    overflow: hidden;
    max-height: 19px;
    text-overflow: unset;
    white-space: normal;
}

.sf-customize-space-label-selected {
    opacity: 1;
}

.sf-customize-space-outer {
    width: 80px;
    max-width: 80px;
    box-sizing: border-box;
}

@media (min-width: 720px) {
    .sf-customize-space-outer {
        width: 144px;
        max-width: 144px;
    }
}

.sf-customize-space-sold-out {
    font-weight: bold;
    background-color: white;
    color: #ff85d8;
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 45%;
}

.sf-customize-space-selected {
    border: solid white 1px;
    opacity: 1;
}

.sf-customize-space-outer-selected {
    opacity: 1;
}

.sf-customize-space-image {
    width: 100%;
    height: 100%;
    image-rendering: pixelated;
}

.sf-customize-space-button {
    padding: 0;
    position: relative;
}

.sf-customize-space-button:disabled {
    opacity: 1;
}

.sf-customize-control {
    min-height: 64px;
    height: 64px;
    max-height: 64px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: stretch;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: border-box;
    background-color: white;
}

@media (max-width: 720px) {
    .sf-menu-tab {
        font-size: 12px;
    }
}