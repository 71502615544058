
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.overlay-menu-visible {
  right: 400px;
}

@media (max-width: 720px) {
  .overlay-menu-visible {
    display: none;
  }
}

.intro {
  background-color: #ff67cfBF;
  height: 100%;
  color: white;
  pointer-events: all;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-title {
  margin-bottom: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}

@media (max-width: 720px) {
  .intro-title {
    font-size: 32px;
    max-width: 75%;
  }
}

@media (min-width: 720px) {
  .intro-title {
    margin-bottom: 16px;
  }
}

.intro-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.intro-form-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: min(396px, 100%);
}

.intro-row {
  margin-bottom: 32px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.intro-row-select {
  display: flex;
  flex-direction: column;
}

.avatar-select {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 16px;
  min-height: 64px;
  margin-bottom: 8px;
  background-color: #ff67cfBF;
  border: rgba(255, 255, 255, 0.6) 1px solid;
  border-radius: 8px;
}

.avatar-select-empty {
  justify-content: center;
  align-items: center;
}

@media (min-width: 720px) {
  .avatar-select {
    min-height: 96px;
    margin-bottom: 16px;
  }
}

.avatar-select::-webkit-scrollbar {
  background: #ff67cfBF;
  border-radius: 8px;
}

.avatar-select::-webkit-scrollbar-track-piece {
  background: #ff67cfBF;
  border-radius: 8px;
}

.avatar-select-item {
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 1px 1px 4px white;
  overflow: hidden;
  opacity: 0.6;
}

@media (min-width: 720px) {
  .avatar-select-item {
      height: 96px;
      width: 96px;
      min-height: 96px;
      min-width: 96px;
  }
}

.avatar-select-item-image {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 8px;
  image-rendering: pixelated;
}

.selected-avatar {
  opacity: 1;
  border: white 1px solid;
  box-sizing: border-box;
}

.radio-row {
  display: flex;
  align-items: center;
}

.radio-row:not(:last-child) {
  margin-bottom: 4px;
}

.intro-row-title {
  font-size: 40px;
}

.face-image {
  background-color: white;
}

.header {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  transition: background-color 1s, color 1s, visibility 1s, max-height 0.5s;
  visibility: hidden;
  color: rgba(255, 255, 255, 0);
  pointer-events: all;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: calc(16px + env(safe-area-inset-top));
  max-height: 19%;
  max-height: 25%;
  box-sizing: border-box;
}

.header-visible {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 1);
  visibility: visible;
}

.header-description-expanded {
  max-height: 100%;
}

.name {
  display: block;
  font-size: 18px;
  line-height: 24px;
  min-height: 24px;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  margin-top: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: pre-wrap;
  max-width: 100%;
  display: none;
}

.description::-webkit-scrollbar {
  height: 0;  /* Remove scrollbar space */
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.description-visible {
  display: initial;
}

.top-row {
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  min-height: 48px;
}

.expand {
  display: none;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  display: none;
}

.expand-button-visible {
  display: flex;
}

.share-button {
  left: 0;
  bottom: calc(0px + env(safe-area-inset-bottom));
  background: url('./img/box-arrow-down.svg');
  background-size: contain;
  transform: rotate(180deg);
}

.game-button {
  position: absolute;
  opacity: 0.6;
  margin: 16px;
  image-rendering: pixelated;
  padding: 0;
  visibility: visible;
  filter: drop-shadow(0 0 5px rgb(0 0 0));
}

.game-button-hidden {
  visibility: hidden;
  opacity: 0;
}

.game-button:active {
  opacity: 1 !important;
}

.back-button {
  background: url('./img/arrow-right.svg');
  transform: rotate(180deg);
  background-size: contain;
  left: 0;
  top: calc(0px + env(safe-area-inset-top));
}

/* @media (max-width: 720px) { */
  .game-button {
    width: 58px;
    height: 58px;
  }
/* } */

/* @media (min-width: 720px) {
  .game-button {
    width: 116px;
    height: 116px;
  }
} */