.space-factory-intro-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.space-factory-footer {
    max-width: 500px;
    overflow-x: auto;
}

.sf-footer-item {
    box-sizing: border-box;
    opacity: 0.2;
}

.sf-footer-item-selected {
    border: solid white 1px;
    opacity: 1;
}

.space-factory-subtitle {
}