.swap-modal-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
    z-index: 2;
}

.swap-modal-inner-container {
    width: 592px;
    height: 512px;
    max-width: 100%;
    max-height: 100%;;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 1.2px solid #FFF1FA;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    padding: 16px;
    border-radius: 8px;
}

@media (max-width: 720px) {
    .swap-modal-inner-container {
        height: 100%;
        width: 100%;
    }
}

.swap-modal-body {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #ff9ddf;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    padding-top: 24px;
    box-sizing: border-box;
    overflow: hidden;
}

.swap-modal-title {
    font-size: 20px;
    margin-bottom: 8px;
}

@media (max-width: 720px) {
    .swap-modal-title {
        font-size: 16px;
    }
}

.swap-modal-text {
}

@media (max-width: 720px) {
    .swap-modal-text {
        font-size: 12px;
    }
}

.swap-modal-list-container {
    margin-top: 16px;
    overflow-y: scroll;
    height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.swap-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
}