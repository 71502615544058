.marketplace-title {
    font-size: 32px;
}

@media (max-width: 720px) {
    .marketplace-title {
        font-size: 24px;
    }
}

.marketplace-subtitle {
    margin-bottom: 4px;
}

@media (max-width: 720px) {
    .marketplace-subtitle {
        font-size: 12px;
    }
}

.marketplace-container {
    overflow-y: auto;
    padding-bottom: 16px;
}

.marketplace-inner-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
}

@media (max-width: 720px) {
    .marketplace-inner-container {
        margin-top: 16px;
    }
}

.marketplace-spaces {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.marketplace-item {
    height: 128px;
    width: 128px;
    min-height:128px;
    min-width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 1px 1px 4px white;
    overflow: hidden;
}

@media (min-width: 720px) {
    .marketplace-item {
        height: 192px;
        width: 192px;
        min-height: 192px;
        min-width: 192px;
    }
}

.marketplace-item-image {
    width: 100%;
    height: 100%;
}