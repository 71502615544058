.hyaliko-avatar-interface {
    position: absolute;
    width: 100%;
    height: 100%;
    touch-action: none;
    pointer-events: none;
}

.hyaliko-avatar-canvas {
    width: 512px;
    height: 512px;
    top: 0;
    position: absolute;
    left: calc(50% - (512px / 2));
}

.avatar-view-button {
    height: 36px !important;
    margin-bottom: 6px;
}