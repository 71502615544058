.modal-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
}

.modal-body {
    width: 75%;
    max-width: 560px;
    height: 240px;
    background-color: #ff9ddf;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 720px) {
    .modal-body {
        height: 200px;
    }
}

.modal-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    box-sizing: border-box;
    padding-left: 32%;
    padding-right: 32%
}

@media (max-width: 720px) {
    .modal-button-row {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 32px;
}

.modal-title {
    font-size: 20px;
    margin-bottom: 8px;
}

@media (max-width: 720px) {
    .modal-title {
        font-size: 16px;
    }
}

.modal-text {
    margin-left: 32px;
    margin-right: 32px;
}

@media (max-width: 720px) {
    .modal-text {
        font-size: 12px;
    }
}

.modal-button {
    font-weight: bold;
}