.redemption-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.redemption-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 720px;
    padding: 32px;
    text-align: center;
}

.redemption-status {
    padding: 8px;
    border-radius: 8px;
}

.redemption-status-unredeemed {
    background-color: white;
    color: #ff85d8;
}