@font-face {
  /* font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
   */
   font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  /* font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
   */
   font-family: 'Alata-Regular';
  src: local('Alata-Regular'), url(./fonts/Alata-Regular.ttf) format('truetype');
  
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  /* font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
   */
   font-family: 'VT323-Regular';
  src: local('VT323-Regular'), url(./fonts/VT323-Regular.ttf) format('truetype');
  
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

* {
  font-family: Montserrat-Regular;
}

.not-single-page {
  scroll-behavior: smooth;
  max-height: unset;
  overflow: unset;
}

.not-single-page::-webkit-scrollbar {
  background: unset;
  border-radius: unset;
  width: 0;
}

.not-single-page::-webkit-scrollbar-track-piece {
  background: unset;
  border-radius: unset;
}

.not-single-page::-webkit-scrollbar-thumb {
  background: unset;
  border-radius: unset;
}

.opacity-transition {
  transition: opacity 1s ease, transform 1.2s ease;
}

a:focus {
  border: none !important;
  outline: 0 !important;
}

button {
  text-decoration: underline;
  background: none;
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: inherit;
  font-size: 16px;
  line-height: 16px;
  color: inherit;
  border-radius: 8px;
  padding: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button:disabled {
  opacity: 0.2;
}

.hidden-on-mobile {  
}

@media (max-width: 720px) {
  .hidden-on-mobile {
      display: none !important;
  }
}

.only-on-mobile {
}

@media (min-width: 721px) {
  .only-on-mobile {
    display: none !important;
  }
}




.hidden-on-small-screen {  
}

@media (max-width: 1024px) {
  .hidden-on-small-screen {
      display: none !important;
  }
}

.only-on-small-screen {
}

@media (min-width: 1025px) {
  .only-on-small-screen {
    display: none !important;
  }
}

.big-button {
  font-size: 32px;
  line-height: 32px;
  background-color: #ff9ddf;
  max-width: 320px;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 1px 1px 4px white;
  text-decoration: none;
}

a {
  color: white;
}

h1 {
  font-size: 48px;
  color: white;
  margin: 0;
}

h2 {
  font-size: 16px;
  color: white;
  margin: 0;
}

::-webkit-scrollbar {
  background: #ff67cfBF;
  border-radius: 8px;
}

::-webkit-scrollbar-track-piece {
  background: #ff67cfBF;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #ff9ddf;
  border-radius: 8px;
}

.text-input {
  max-width: 300px;
  border: rgba(255, 255, 255, 0.6) 1px solid;
  border-radius: 8px;
  outline: 0;
  background-color: #ff67cfBF;
  color: white;
}

.big-text-input {
  font-size: 32px;
  max-width: 320px;
  border: rgba(255, 255, 255, 0.6) 1px solid;
  border-radius: 8px;
  outline: 0;
  background-color: #ff67cfBF;
  color: white;
  text-align: center;
}

.form-text-input {
  text-align: left;
  padding-left: 8px;
}

@media (max-width: 720px) {
  .big-text-input {
    max-width: 240px;
  }
}

.text-input:focus {
  border: white 1px solid;
  outline: 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.6;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
  opacity: 0.6;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
  opacity: 0.6;
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.page {
  position: absolute;
  left: 0;
  width: 100vw;
  top: 0;
  bottom: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
  transition-timing-function: ease;
}

.page-exit {
  opacity: 0;
  transition: opacity 1000ms;
  transition-timing-function: ease;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
  transition-timing-function: ease;
}