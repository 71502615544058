.menu {
    position: absolute;
    right: -400px;
    top: 0;
    bottom: 0;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.6);
    transition: right 0.1s;
    display: flex;
    flex-direction: column;
}

@media (max-width: 720px) {
    .menu {
      width: 100%;
      right: -100%;
    }
}

.menu-visible {
    right: 0;
}

.menu-header {
    height: 64px;
    padding-top: env(safe-area-inset-top);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ff67cfBF;
}

.menu-tab {
    flex: 1;
    height: 64px;
    border-radius: 0;
    font-weight: normal;
    color: white;
    margin: 0;
}

.active-menu-tab {
    font-weight: bold;
    color: #ff67cfBF;
    background-color: white;
}

.chat-body {
    height: 100%;
    width: 100%;
}

.people-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding: 4px;
    padding-bottom: calc(4px + env(safe-area-inset-bottom));
}

.art-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding: 4px;
    padding-bottom: calc(4px + env(safe-area-inset-bottom));
}

.menu-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
}

.menu-item {
    color: black;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    word-wrap: break-word;
    text-decoration: none;
}

.menu-item:not(:last-child) {
    margin-bottom: 8px;
}

.chat-messages {
    overflow-y: auto;
    position: absolute;
    top: calc(64px + env(safe-area-inset-top));
    left: 0;
    right: 0;
    bottom: calc(64px + env(safe-area-inset-bottom));
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: flex-end; DO NOT USE: breaks scrolling */
}

.chat-messages > :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
}

.chat-message {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.8);
    word-wrap: break-word;
}

.chat-message-me {
    background-color: #ff67cfBF;
    color: white;
}

.chat-message:not(:last-child) {
    margin-bottom: 8px;
}

.chat-message-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chat-message-body {

}

.chat-message-name {
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.chat-control {
    position: absolute;
    bottom: 0;
    height: calc(64px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: calc(12px + env(safe-area-inset-bottom));
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.8);
}

.chat-input {
    font-size: 20px;
    text-align: left;
    padding-left: 8px;
    margin-right: 8px;
    flex: 1;
    max-width: unset;
}

.menu-button {
    color: white;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px #ffceef;
}