.new-home-container p {
  font-size: 24px;
}

@media (max-width: 1024px) {
    .new-home-container p {
        font-size: 18px;
        margin-top: 9px;
        margin-bottom: 9px;
      }
}

.new-home-container * {
    font-family: Alata-Regular;
}

.new-home-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    height: 60px;

    background-color: #FFFFFF;
    border-radius: 12px;

    font-family: VT323-Regular;
    font-weight: 400;
    font-size: 28px;

    color: #FF7CCF;

    text-decoration: none;

    min-width: 306px;
}

.new-home-small-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    height: 44px;

    background: #FFFFFF;
    border-radius: 100px;

    font-family: VT323-Regular;
    font-weight: 400;
    font-size: 24px;

    color: #FF7CCF;

    text-decoration: none;
}

.new-home-extra-small-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    height: 20px;

    background: #FFFFFF;
    border-radius: 100px;

    font-family: VT323-Regular;
    font-weight: 400;
    font-size: 20px;

    color: #FF7CCF;

    text-decoration: none;
}

.new-home-featured-gallery-button {
    width: 100%;
    margin-top: 28px;
}

.new-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-home-top-section {
    background-color: transparent;
    height: 636px;
    width: 100%;
    max-width: 1900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    padding-left: 48px;
    padding-right: 48px;
    box-sizing: border-box;
}

@media (max-width: 1024px) {
    .new-home-top-section {
        padding-left: 16px;
        padding-right: 16px;
        height: 348px;
    }
}

.new-home-top-section-placeholder {
    position: relative;
    top: 0;
}

.new-home-top-section-video {
    overflow: hidden;
}

.new-home-big-text-input {
    width: 726px;
    height: 92px;
    border-radius: 24px;
    border: none;
    font-size: 17px;
    outline: 0;
    color: #444444;
    background-color: white;
    padding-left: 24px;
    box-sizing: border-box;
    position: relative;
}

@media (max-width: 1024px) {
    .new-home-big-text-input {
        width: 353px;
    }
  }

.new-home-big-text-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #444444;
    opacity: 0.6;
    font-size: 17px;
  }

.new-home-text {
    font-family: Alata-Regular;
    font-weight: 400;
    color: white;
    text-shadow: 0 0 14px #0000003f;
}

.new-home-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 122px;
}

@media (max-width: 1024px) {
    .new-home-title {
        font-size: 65px;
    }
}

.new-home-subtitle {
    font-size: 32px;
    margin: 0;
    text-align: center;
}

@media (max-width: 1024px) {
    .new-home-subtitle {
        font-size: 20px;
    }
}

.new-home-subtitle:first-of-type {
    margin-bottom: 36px;
}

@media (max-width: 1024px) {
    .new-home-subtitle:first-of-type {
        margin-bottom: 9px;
    }
}

.new-home-subtitle:last-of-type {
    margin-bottom: 80px;
}

.new-home-video {
    position: absolute;
    top: -200px;
    z-index: -1;
    object-fit: cover;
    min-height: 636px;
}

@media (max-width: 1024px) {
    .new-home-video {
        min-height: 348px;
        top: -400px;
    }
}

.new-home-header {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    box-sizing: border-box;
    margin-bottom: 124px;
    min-height: 73px;
}

@media (max-width: 1024px) {
    .new-home-header {
        margin-bottom: 30px;
        min-height: unset;
    }
}


.bm-burger-button {
  width: 33px;
  height: 22px;
  position: relative;
}

@media (min-width: 1025px) {
    .bm-burger-button {
      display: none !important;
    }
}

.bm-cross-button {
    padding: 8px;
}

.bm-cross {
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #ff85d8;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    padding-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
  }

  .bm-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    font-family: VT323-Regular;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

.new-home-mint-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-home-header-link {
    font-family: VT323-Regular;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 44px;
    text-shadow: 0 0 5px #0000003f;
}

.new-home-header-mint-info {
    margin-top: 9px;
}

.new-home-subtext {
    font-family: VT323-Regular;
    font-weight: 400;
    font-size: 20px;
    color: white;
    text-align: center;
}

#newHomeRenderCanvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.new-home-header-left {
    display: flex;
    flex-direction: row;
    gap: 53px;
}

.new-home-header-right {
    display: flex;
    flex-direction: row;
    gap: 53px;
}

.new-home-bottom-section {
    display: flex;
    flex-direction: column;
    padding-top: 133px;
    padding-bottom: 64px;
    box-sizing: border-box;
    align-items: center;
    max-width: 1400px;
}

.new-home-input-container {
    position: absolute;
    bottom: -74px;
}

.new-home-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 9px;
}

.new-home-continue-button {
    position: absolute;
    right: 22px;
    background: url('./img/arrow-right.svg');
    background-size: 50%;
    background-color: #FF7CCF;
    padding: 0;
    height: 47px;
    width: 47px;
    border-radius: 47px;
    padding: 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.new-home-featured-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 95px;
}

@media (max-width: 1024px) {
    .new-home-featured-section {
        padding-bottom: 52px;
    }
}

.new-home-featured-title {
    font-size: 42px;
    margin-bottom: 33px;
}

@media (max-width: 1024px) {
    .new-home-featured-title {
        font-size: 24px;
    }
}

.new-home-featured-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1082px;
}

@media (max-width: 1024px) {
    .new-home-featured-row {
        flex-direction: column;
        align-items: center;
    }
}

.new-home-image-container {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 1.2px solid #FFF1FA;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.new-home-featured-gallery-image-overflow {
    overflow: hidden;
    width: 286px;
    height: 286px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
}

.new-home-image {
    border-radius: 24px;
}

.new-home-featured-gallery-image-container {
    width: 306px;
    height: 306px;
}

.new-home-featured-gallery-image {
    width: 296px;
    height: 296px;
}

.new-home-learn-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 124px;
    padding-right: 124px;
    box-sizing: border-box;
    width: 100%;
}

@media (max-width: 1024px) {
    .new-home-learn-section {
        border-top: 1px solid #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        padding-left: 36px;
        padding-right: 36px;
    }
}

.new-home-learn-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 50px;
    margin-bottom: 124px;
}

@media (max-width: 1024px) {
    .new-home-learn-row {
        padding-top: 42px;
        margin-bottom: 33px;
        width: unset;
    }
}

.new-home-learn-title {
    font-size: 42px;
    margin-bottom: 33px;
}

@media (max-width: 1024px) {
    .new-home-learn-title {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 7px;
    }
}

.new-home-learn-row:first-of-type {
    border-top: 1px solid #FFFFFF;
}

.new-home-learn-row:last-of-type {
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 98px;
}

@media (max-width: 1024px) {
    .new-home-learn-row:first-of-type {
        border-top: none;
    }
    
    .new-home-learn-row:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }
}


.new-home-learn-image {
    position: relative;
    flex: 1;
    display: flex;
}

.new-home-learn-image-grid {
    display: flex;
    flex: 1;
    align-items: unset;
    max-width: 472px;
    min-width: 472px;
    max-height: 472px;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 145px;
}

.new-home-learn-text {
    flex: 1;
}

@media (max-width: 1024px) {
    .new-home-learn-text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.new-home-learn-orb {
    position: absolute;
    width: 520px;
    height: 520px;
}

.orb1 {
    top: -50px;
    right: -10px;
}

.orb2 {
    top: 20px;
    right: -110px;
}

.orb3 {
    top: 100px;
    right: 60px;
}

.new-home-space-image {
    width: 206px;
    height: 206px;
}

.new-home-space-image-overflow {
    overflow: hidden;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
}


.new-home-space-image-container {
    width: 220px;
    height: 220px;
}

.new-home-first-edition-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 124px;
    padding-right: 124px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 1024px) {
    .new-home-first-edition-section {
        padding-top: 42px;
        padding-left: 36px;
        padding-right: 36px;
        text-align: center;
    }
}

.new-home-first-edition-galleries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    gap: 80px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1024px) {
    .new-home-first-edition-galleries {
        gap: 24px;
    }
}
