.about-container {
    display: flex;
    background-color: rgba(0, 0, 0, 0);
    max-width: 100%;
}

.about-inner-container {
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    gap: 32px;
    max-width: 100%;
}

@media (max-width: 720px) {
    .about-inner-container {
        gap: 8px;
    }
}

@media (max-height: 750px) {
    .about-inner-container {
        gap: 8px;
    }
}

.about-section {
    padding: 16px;
    padding-top: 32px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

@media (max-width: 720px) {
    .about-section {
        padding: 12px;
        padding-top: 16px;
    }
}

@media (max-height: 750px) {
    .about-section {
        padding: 12px;
        padding-top: 16px;
    }
}

.about-title {
    font-size: 20px;
}

.about-title:first-of-type {
    margin-bottom: 16px;
}

@media (max-width: 720px) {
    .about-title:first-of-type {
        margin-bottom: 8px;
    }
}

@media (max-height: 750px) {
    .about-title:first-of-type {
        margin-bottom: 8px;
    }
}

.about-mint-button {
    margin-top: 32px;
    text-align: center;
}

.important-link {
    background-color: white;
    color: #ff85d8;
    padding: 8px;
    border-radius: 8px;
}

@media (max-width: 720px) {
    .about-title {
        font-size: 16px;
    }
}

@media (max-height: 750px) {
    .about-title {
        font-size: 16px;
    }
}

#aboutRenderCanvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}