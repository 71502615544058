.space-factory-title {
    text-align: center;
}

.space-factory-subtitle {
    margin-bottom: 16px;
    text-align: center;
}

.space-factory-subtitle:last-of-type {
    margin-bottom: 32px;
}

.space-factory-container {
    display: flex;
}

.space-factory-inner-container {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 640px;
    padding-left: 16px;
    padding-right: 16px;
}

.space-factory-begin {
}