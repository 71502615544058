.curate-empty-state {
    width: 100%;
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: black;
}

.curate-empty-state button {
    font-weight: bold;
}

.curate-empty-state-text {
    margin-top: 8px;
}

.curate-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curate-checkbox {
    border: black 1px solid;
    height: 16px;
    width: 16px;
    border-radius: 4px;
}

.curate-control {
    min-height: calc(64px + env(safe-area-inset-bottom));
    height: calc(64px + env(safe-area-inset-bottom));
    max-height: calc(64px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: stretch;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: calc(12px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    background-color: white;
}

.curate-counter {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.curate-counter-limit {
    color: red;
}

.curate-outer-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    max-height: calc(100vh - 64px - env(safe-area-inset-bottom));
}

.curate-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: auto;
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
}

.curation-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(0deg, #ff67cf40, #ff67cf40);
    border: 1.2px solid #FFF1FA;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    margin-top: 16px;
}

.curation-inner-section {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: stretch;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    padding-top: 16px;
    padding-bottom: 16px
}

.curation-section-text {
    display: inline-block;
}

.curation-top-button-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.curation-container-expand {
    background-color: #ff67cfBF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    color: white;
    text-decoration: none;
}

.curation-show-all-button {
    margin-top: 8px;
    margin-bottom: 16px;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.curation-container-expanded {
}

.curate-saving {
    opacity: 0.2;
}

.curate-menu-item-hidden {
    opacity: 0.2;
}

.curate-button {
    min-width: 120px;
}

.curation-collaborator-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.curation-spaces {
    padding: 16px;
    padding-bottom: 0 !important;
    background: none;
    margin: 8px;
    border-radius: 8px;
}

.curation-space-row {
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 1.2px solid #FFF1FA;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    padding: 8px;
    margin-bottom: 16px;
}

.curation-space-row-hidden {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

.curation-space-row-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background-color: #FF7CCF;
}

.curation-space-row-inner-hidden {
    background-color: #FF7CCF40;
}

.curation-space-content-hidden {
    opacity: 0.3;
}

.curation-space-row-hover:hover {
    filter: brightness(1.1);
}

.curation-space-image {
    height: 96px;
    width: 96px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 1.2px solid #FFF1FA;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    padding: 8px;
    border-radius: 8px;
}

.curation-space-text {
    color: white;
    display: flex;
    margin-left: 16px;
    width: 100%;
    max-height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.curate-space-checkbox {
    min-height: 32px;
    min-width: 32px;
    max-height: 32px;
    max-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 16px;
    opacity: 0.4;
    background-color: white;
}

.curate-space-checkbox:hover {
    opacity: 0.9
}

.curate-checkbox-hidden {
    opacity: 1;
}

.curate-checkbox-hidden:hover {
    opacity: 1;
}