#homeRenderCanvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #ff85d8;
    color: white;
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
}

.home-container {
    background-color: rgba(0, 0, 0, 0);
}

.home-header {
    padding: 16px 16px;
    opacity: 0.6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 720px) {
    .home-header {
      font-size: 12px;
    }
}

@media (min-width: 720px) {
    .home-header-link:not(:first-child) {
        padding-left: 8px;
    }
}

.inner-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-or {
   font-size: 32px; 
   margin-top: 16px;
   margin-bottom: 16px;
}

@media (max-width: 720px) {
    .home-or {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.home-title {
}

.home-subtitle {
    margin-bottom: 4px;
}

@media (max-width: 720px) {
    .home-subtitle {
        font-size: 12px;
    }
}

.home-subtitle:last-of-type {
    margin-bottom: 16px;
}


.home-text {
    text-align: center;
}

.home-text:last-of-type {
    margin-bottom: 8px;
}

@media (max-width: 720px) {
    .home-text {
        font-size: 12px;
    }
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.continue-button {
    background: url('./img/arrow-right.svg');
    background-size: 80%;
    padding: 0;
    height: 43px;
    width: 43px;
    margin-left: 16px;
    padding: 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.marketplace-button {
    background-color: white;
    color: #ff85d8;
    box-shadow: 1px 1px 6px white;
}

@media (max-width: 720px) {
    .marketplace-button {
        max-width: 240px;
        line-height: 24px;
        font-size: 24px;
        padding: 12px;
    }
}

.metamask-button {
    margin-top: 32px;
}

.footer {
    background-color: #ff67cf;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: env(safe-area-inset-bottom);
}

.footer-header {
    height: 64px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 0 16px;
}

@media (max-width: 720px) {
    .footer-header {
      font-size: 12px;
      padding: 8px 8px 0 8px;
    }
}

.footer-body {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 16px;
    padding-bottom: 32px;
    min-height: 64px;
    margin-bottom: 8px;
}

@media (min-width: 720px) {
    .footer-body {
      min-height: 96px;
      margin-bottom: 16px;
    }
}

.footer-item {
    height: 64px;
    width: 64px;
    min-height: 64px;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 1px 1px 4px white;
    overflow: hidden;
}

@media (min-width: 720px) {
    .footer-item {
        height: 96px;
        width: 96px;
        min-height: 96px;
        min-width: 96px;
    }
}

.footer-item-image {
    width: 100%;
    height: 100%;
}

.footer-item-empty {
    background-color:#ff9ddf;
}

.footer-item:not(:first-child) {
    margin-left: 32px;
}