.dialogue-box-outer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 720px) {
    .dialogue-box-outer-container {
      bottom: 16px;
      height: 224px;
    }
  }


.dialogue-box-container {
    height: 100%;
    width: 720px;
    max-width: 720px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    border: 1.2px solid #FFF1FA;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
    overflow: hidden;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: 16px;
    margin-right: 16px;
}

.dialogue-box-inner-container {
    overflow: hidden;
    height: 135px;
    width: 100%;
    max-height: 1135x;
    white-space: pre-line;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 720px) {
    .dialogue-box-inner-container {
      height: 135px;
      max-height: 135px;
    }
}

.dialogue-box-text {
    font-family: VT323-Regular;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    text-shadow: 0 0 14px #0000003f;
    color: #FFFFFF;
}

.dialogue-box-error {
  font-size: 20px;
  background-color: #FE0302;
  border-radius: 4px;
  padding: 8px;
  color: white;
}

@media (max-width: 720px) {
    .dialogue-box-text {
      font-size: 24px;
      line-height: 27px;
    }
}

.dialogue-box-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.dialogue-box-button {
  background-color: #FFFFFF;
  padding: 0;
  height: 54px;
  min-width: 216px;
  border-radius: 13px;
  padding: 16px;
  font-family: VT323-Regular;
  font-weight: 400;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-decoration: none;
}

.dialogue-box-button:not(:first-of-type) {
  margin-left: 64px;
}

.dialogue-box-continue-button {
    background: url('./img/arrow-right-black.svg');
    background-size: 50%;
    background-color: #FFFFFF;
    padding: 0;
    height: 47px;
    width: 47px;
    border-radius: 47px;
    padding: 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.dialogue-box-input {
  background-color: #000000;
}